define("discourse/plugins/discourse-nc-customfields/discourse/connectors/composer-fields/topic-custom-field-composer", ["exports", "@glimmer/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Topic Custom Field Composer
   * 
   * If your field can be edited by users, you need to show an input in the composer.
   * 
   * References:  app/assets/javascripts/discourse/app/templates/composer.hbs
   */
  class TopicVersionComposer extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "composerModel", [(0, _computed.alias)("args.outletArgs.model")]))();
    #composerModel = (() => (dt7948.i(this, "composerModel"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "topic", [(0, _computed.alias)("composerModel.topic")]))();
    #topic = (() => (dt7948.i(this, "topic"), void 0))();
    constructor() {
      super(...arguments);
      // Field name and value for version
      this.versionFieldName = "version";
      this.versionFieldValue = this.InitValue(this.versionFieldName);

      // Field name and value for isBlockedInProduction
      this.blockedFieldName = "isBlockedInProduction";
      this.blockedFieldValue = this.InitValue(this.blockedFieldName);
    }

    // Value changed for version
    onVersionChangeField(fieldValue) {
      this.composerModel.set(this.versionFieldName, fieldValue);
    }

    // Value changed for isBlockedInProduction
    static #_3 = (() => dt7948.n(this.prototype, "onVersionChangeField", [_object.action]))();
    onBlockedChangeField(fieldValue) {
      this.composerModel.set(this.blockedFieldName, fieldValue);
    }

    // If the first post is being edited we need to pass our value from
    // the topic model to the composer model.
    static #_4 = (() => dt7948.n(this.prototype, "onBlockedChangeField", [_object.action]))();
    InitValue(fieldName) {
      if (!this.composerModel[fieldName] && this.topic && this.topic[fieldName]) {
        this.composerModel.set(fieldName, this.topic[fieldName]);
      }
      return this.composerModel.get(fieldName);
    }
  }
  _exports.default = TopicVersionComposer;
});