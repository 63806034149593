define("discourse/plugins/discourse-nc-customfields/discourse/api-initializers/topic-list-customization", ["exports", "discourse/lib/api", "discourse/plugins/discourse-nc-customfields/global", "discourse/plugins/discourse-nc-customfields/discourse/item/version-field-cell", "discourse/plugins/discourse-nc-customfields/discourse/item/blocked-prod-field-cell", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _api, _global, _versionFieldCell, _blockedProdFieldCell, _i18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /** 
   * This module customizes the topic list in Discourse by adding custom columns based on the `CUSTOM_FIELDS` configuration.
   * It uses the Discourse API to register additional columns with custom headers and cell templates.
   * 
   * Key Features:
   * - Dynamically adds custom columns to the topic list based on the `CUSTOM_FIELDS` array.
   * - Each column is defined with a header template and a cell template.
   * 
   * Components:
   * - `headerTemplates`: A map defining the header templates for each custom column.
   * - `cellTemplates`: A map defining the cell rendering components for each custom column.
   * 
   * API Integration:
   * - Uses `api.registerValueTransformer` to modify the `topic-list-columns` by appending custom columns.
   * 
   * Dependencies:
   * - `CUSTOM_FIELDS`: An array of custom field configurations, each containing a `name` and `type`.
   */
  var _default = _exports.default = (0, _api.apiInitializer)("1.34", api => {
    const siteSettings = api.container.lookup("site-settings:main");
    const fieldName = siteSettings.topic_custom_field_name;
    // Map defining the header templates for custom columns in the topic list.
    // Each key corresponds to a custom field name, and the value is the header template.
    const headerTemplates = new Map([["version", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
          <th style="text-align: center; vertical-align: middle;">{{i18n "topic_custom_field.version.list_header"}}</th>
        
    */
    {
      "id": "jGYTLOHq",
      "block": "[[[1,\"\\n      \"],[10,\"th\"],[14,5,\"text-align: center; vertical-align: middle;\"],[12],[1,[28,[32,0],[\"topic_custom_field.version.list_header\"],null]],[13],[1,\"\\n    \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-nc-customfields/discourse/api-initializers/topic-list-customization.js",
      "scope": () => [_i18n.default],
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined))], ["isBlockedInProduction", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
          <th style="text-align: center; vertical-align: middle;">{{i18n "topic_custom_field.isBlockedInProduction.list_header"}}</th>
        
    */
    {
      "id": "Gq6v06KL",
      "block": "[[[1,\"\\n      \"],[10,\"th\"],[14,5,\"text-align: center; vertical-align: middle;\"],[12],[1,[28,[32,0],[\"topic_custom_field.isBlockedInProduction.list_header\"],null]],[13],[1,\"\\n    \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-nc-customfields/discourse/api-initializers/topic-list-customization.js",
      "scope": () => [_i18n.default],
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined))]]);
    // Map defining the cell templates for custom columns in the topic list.
    // Each key corresponds to a custom field name, and the value is the component used to render the cell.
    const cellTemplates = new Map([["version", _versionFieldCell.default], ["isBlockedInProduction", _blockedProdFieldCell.default]]);
    api.registerValueTransformer("topic-list-columns", _ref => {
      let {
        value: columns
      } = _ref;
      // Add each custom column:
      _global.CUSTOM_FIELDS.forEach(field => {
        columns.add(field.name, {
          header: headerTemplates.get(field.name),
          item: cellTemplates.get(field.name)
        });
      });
      return columns;
    });
  });
});