define("discourse/plugins/discourse-nc-customfields/discourse/connectors/edit-topic/topic-custom-field-edit-topic", ["exports", "@glimmer/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
   * type:        step
   * number:      6
   * title:       Show an input in topic title edit
   * description: If your field can be edited by the topic creator or
   *              staff, you may want to let them do this in the topic
   *              title edit view.
   * references:  app/assets/javascripts/discourse/app/templates/topic.hbs
   */
  /**
   * This component extends the topic title edit functionality in Discourse to include custom fields.
   * It allows the topic creator or staff to edit specific custom fields directly in the topic title edit view.
   * 
   * Key Features:
   * - Dynamically integrates custom fields (`version` and `isBlockedInProduction`) into the topic edit interface.
   * - Handles initialization and value changes for the custom fields.
   * - Updates the buffered model with the new values for the custom fields.
   * 
   * Custom Fields:
   * - `version`: Represents the version of the topic.
   * - `isBlockedInProduction`: Indicates whether the topic is blocked in production.
   * 
   * Methods:
   * - `InitValue(fieldName)`: Retrieves the initial value of a custom field from the model.
   * - `onVersionChangeField(fieldValue)`: Updates the `version` field value in the buffered model.
   * - `onBlockedChangeField(fieldValue)`: Updates the `isBlockedInProduction` field value in the buffered model.
   */
  class TopicVersionEditTopic extends _component.default {
    constructor() {
      super(...arguments);
      // Field name and value for version
      this.versionFieldName = "version";
      this.versionFieldValue = this.InitValue(this.versionFieldName);

      // Field name and value for isBlockedInProduction
      this.blockedFieldName = "isBlockedInProduction";
      this.blockedFieldValue = this.InitValue(this.blockedFieldName);
    }

    // Value changed for version
    onVersionChangeField(fieldValue) {
      this.args.outletArgs.buffered.set(this.versionFieldName, fieldValue);
    }

    // Value changed for isBlockedInProduction
    static #_ = (() => dt7948.n(this.prototype, "onVersionChangeField", [_object.action]))();
    onBlockedChangeField(fieldValue) {
      this.args.outletArgs.buffered.set(this.blockedFieldName, fieldValue);
    }
    static #_2 = (() => dt7948.n(this.prototype, "onBlockedChangeField", [_object.action]))();
    InitValue(fieldName) {
      return this.args.outletArgs.model.get(fieldName);
    }
  }
  _exports.default = TopicVersionEditTopic;
});