define("discourse/plugins/discourse-nc-customfields/discourse/components/topic-custom-field-input", ["exports", "@glimmer/component", "@ember/component", "@ember/modifier", "@ember/object/computed", "@ember/service", "truth-helpers", "discourse-common/helpers/i18n", "discourse/plugins/discourse-nc-customfields/global", "@ember/helper", "@ember/template-factory"], function (_exports, _component, _component2, _modifier, _computed, _service, _truthHelpers, _i18n, _global, _helper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This component dynamically renders an input field for a custom topic field based on its type.
   * The type of the field is determined by matching the `fieldName` with the `CUSTOM_FIELDS` configuration.
   * 
   * Supported field types:
   * - "boolean": Renders a checkbox input.
   * - "integer": Renders a numeric input field.
   * - "string": Renders a text input field.
   * - "json": Renders a text input field.
   * 
   * Props:
   * - `fieldName` (String): The name of the custom field.
   * - `fieldValue` (Any): The current value of the custom field.
   * - `onChangeField` (Function): Callback function triggered when the field value changes.
   * 
   * Localization:
   * - Labels and placeholders are dynamically generated using the `i18n` helper and the `fieldName`.
   */class TopicCustomFieldInput extends _component.default {
    get fieldName() {
      return this.args.fieldName;
    }
    get fieldType() {
      return _global.CUSTOM_FIELDS.find(field => field.name === this.fieldName)?.type ?? "string";
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if (eq this.fieldType "boolean")}}
        <div style="padding: 0.25em 0em 0.25em 0em;">
          <Input
            @type="checkbox"
            @checked={{@fieldValue}}
            {{on "change" (action @onChangeField value="target.checked")}}
          />
          <span style="align-content: center;">{{{i18n (concat "topic_custom_field." this.fieldName ".label")}}}</span>
        </div>
        {{/if}}
    
        {{#if (eq this.fieldType "integer")}}
          <Input
            @type="number"
            @value={{@fieldValue}}
            placeholder={{i18n (concat "topic_custom_field." this.fieldName ".placeholder")}}
            class="topic-custom-field-input"
            {{on "change" (action @onChangeField value="target.value")}}
          />
        {{/if}}
    
        {{#if (eq this.fieldType "string")}}
          <Input
            @type="text"
            @value={{@fieldValue}}
            placeholder={{i18n (concat "topic_custom_field." this.fieldName ".placeholder")}}
            class="topic-custom-field-input"
            {{on "change" (action @onChangeField value="target.value")}}
          />
        {{/if}}
    
        {{#if (eq this.fieldType "json")}}
          <Textarea
            @value={{@fieldValue}}
            {{on "change" (action @onChangeField value="target.value")}}
            placeholder={{i18n (concat "topic_custom_field." this.fieldName ".placeholder")}}
            class="topic-custom-field-textarea"
          />
        {{/if}}
      
    */
    {
      "id": "hOG0pYIP",
      "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"fieldType\"]],\"boolean\"],null],[[[1,\"    \"],[10,0],[14,5,\"padding: 0.25em 0em 0.25em 0em;\"],[12],[1,\"\\n      \"],[8,[32,1],[[4,[32,2],[\"change\",[28,[31,1],[[30,0],[30,2]],[[\"value\"],[\"target.checked\"]]]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,1]]],null],[1,\"\\n      \"],[10,1],[14,5,\"align-content: center;\"],[12],[2,[28,[32,3],[[28,[32,4],[\"topic_custom_field.\",[30,0,[\"fieldName\"]],\".label\"],null]],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"fieldType\"]],\"integer\"],null],[[[1,\"      \"],[8,[32,1],[[16,\"placeholder\",[28,[32,3],[[28,[32,4],[\"topic_custom_field.\",[30,0,[\"fieldName\"]],\".placeholder\"],null]],null]],[24,0,\"topic-custom-field-input\"],[4,[32,2],[\"change\",[28,[31,1],[[30,0],[30,2]],[[\"value\"],[\"target.value\"]]]],null]],[[\"@type\",\"@value\"],[\"number\",[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"fieldType\"]],\"string\"],null],[[[1,\"      \"],[8,[32,1],[[16,\"placeholder\",[28,[32,3],[[28,[32,4],[\"topic_custom_field.\",[30,0,[\"fieldName\"]],\".placeholder\"],null]],null]],[24,0,\"topic-custom-field-input\"],[4,[32,2],[\"change\",[28,[31,1],[[30,0],[30,2]],[[\"value\"],[\"target.value\"]]]],null]],[[\"@type\",\"@value\"],[\"text\",[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"fieldType\"]],\"json\"],null],[[[1,\"      \"],[8,[32,5],[[16,\"placeholder\",[28,[32,3],[[28,[32,4],[\"topic_custom_field.\",[30,0,[\"fieldName\"]],\".placeholder\"],null]],null]],[24,0,\"topic-custom-field-textarea\"],[4,[32,2],[\"change\",[28,[31,1],[[30,0],[30,2]],[[\"value\"],[\"target.value\"]]]],null]],[[\"@value\"],[[30,1]]],null],[1,\"\"]],[]],null],[1,\"  \"]],[\"@fieldValue\",\"@onChangeField\"],false,[\"if\",\"action\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-nc-customfields/discourse/components/topic-custom-field-input.js",
      "scope": () => [_truthHelpers.eq, _component2.Input, _modifier.on, _i18n.default, _helper.concat, _component2.Textarea],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopicCustomFieldInput;
});