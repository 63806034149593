define("discourse/plugins/discourse-nc-customfields/discourse/initializers/topic-custom-field-initializer", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-nc-customfields/global"], function (_exports, _pluginApi, _global) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This initializer integrates custom fields into the Discourse topic lifecycle.
   * It ensures that custom fields are serialized and properly handled during topic creation, draft saving, and topic rendering.
   * 
   * Key Features:
   * - Automatically serializes custom fields when creating a new topic.
   * - Includes custom fields in draft serialization for saving and restoring drafts.
   * - Maps custom fields to the topic model for rendering and access.
   * 
   * Custom Fields:
   * - Dynamically processes all fields defined in the `CUSTOM_FIELDS` configuration.
   * 
   * Methods:
   * - `api.serializeOnCreate(field.name)`: Ensures the custom field is serialized during topic creation.
   * - `api.serializeToDraft(field.name)`: Includes the custom field in draft serialization.
   * - `api.serializeToTopic(field.name, mapping)`: Maps the custom field to the topic model for rendering.
   * 
   * Dependencies:
   * - `CUSTOM_FIELDS`: An array of custom field configurations, each containing a `name`.
   */
  var _default = _exports.default = {
    name: "topic-custom-field-intializer",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("1.37.3", api => {
        _global.CUSTOM_FIELDS.forEach(field => {
          api.serializeOnCreate(field.name);
          api.serializeToDraft(field.name);
          api.serializeToTopic(field.name, `topic.${field.name}`);
        });
      });
    }
  };
});