define("discourse/plugins/discourse-nc-customfields/discourse/templates/connectors/topic-title/topic-custom-field-topic-title", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless this.topic.editingTopic}}
    <!-- Displays the version -->
    {{#if this.versionValue}}
      <span>{{{i18n
          "topic_custom_field.version.label"
          value=this.versionValue
        }}}</span>
    <!-- Displays the is blocked in production -->
    {{#if this.blockedProdValue}}<br/>{{/if}}
    {{/if}}
    {{#if this.blockedProdValue}}
      <span style="color:red"><b>{{{i18n
          "topic_custom_field.isBlockedInProduction.label"
        }}}</b></span>
    {{/if}}
  {{/unless}}
  */
  {
    "id": "TyEkp8fA",
    "block": "[[[41,[51,[30,0,[\"topic\",\"editingTopic\"]]],[[[1,\"  \"],[3,\" Displays the version \"],[1,\"\\n\"],[41,[30,0,[\"versionValue\"]],[[[1,\"    \"],[10,1],[12],[2,[28,[37,3],[\"topic_custom_field.version.label\"],[[\"value\"],[[30,0,[\"versionValue\"]]]]]],[13],[1,\"\\n  \"],[3,\" Displays the is blocked in production \"],[1,\"\\n  \"],[41,[30,0,[\"blockedProdValue\"]],[[[10,\"br\"],[12],[13]],[]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"blockedProdValue\"]],[[[1,\"    \"],[10,1],[14,5,\"color:red\"],[12],[10,\"b\"],[12],[2,[28,[37,3],[\"topic_custom_field.isBlockedInProduction.label\"],null]],[13],[13],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"unless\",\"if\",\"span\",\"i18n\",\"br\",\"b\"]]",
    "moduleName": "discourse/plugins/discourse-nc-customfields/discourse/templates/connectors/topic-title/topic-custom-field-topic-title.hbs",
    "isStrictMode": false
  });
});