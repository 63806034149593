define("discourse/plugins/discourse-nc-customfields/discourse/item/blocked-prod-field-cell", ["exports", "@glimmer/component", "@ember/object/computed", "@ember/service", "@ember/component", "@ember/template-factory"], function (_exports, _component, _computed, _service, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /** 
   * This component is responsible for rendering the `isBlockedInProduction` custom field in a table cell within the topic list.
   * It retrieves the `isBlockedInProduction` field value from the topic model and displays an alert icon (🚨) if the field is true.
   * 
   * Key Features:
   * - Dynamically fetches the `isBlockedInProduction` custom field value from the topic model.
   * - Renders an alert icon (🚨) in a table cell if the field value is true.
   * - Ensures the content is centered and vertically aligned in the table cell.
   * 
   * Properties:
   * - `field`: Retrieves the `isBlockedInProduction` field value from the topic model using `this.args.topic`.
   * 
   * Template:
   * - Displays the alert icon (🚨) in a `<td>` element with bold styling and centered alignment if the field value is true.
   */class BlockedProdFieldCell extends _component.default {
    get field() {
      return this.args.topic.get("isBlockedInProduction");
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <td style="text-align: center; vertical-align: middle;"><b>
        {{#if this.field}}
          🚨
        {{/if}}</b></td>
      
    */
    {
      "id": "TEYJy962",
      "block": "[[[1,\"\\n    \"],[10,\"td\"],[14,5,\"text-align: center; vertical-align: middle;\"],[12],[10,\"b\"],[12],[1,\"\\n\"],[41,[30,0,[\"field\"]],[[[1,\"      🚨\\n    \"]],[]],null],[13],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-nc-customfields/discourse/item/blocked-prod-field-cell.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = BlockedProdFieldCell;
});