define("discourse/plugins/discourse-nc-customfields/discourse/item/version-field-cell", ["exports", "@glimmer/component", "@ember/object/computed", "@ember/service", "@ember/component", "@ember/template-factory"], function (_exports, _component, _computed, _service, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This component is responsible for rendering the `version` custom field in a table cell within the topic list.
   * It retrieves the `version` field value from the topic model and displays it in a bold format.
   * 
   * Key Features:
   * - Dynamically fetches the `version` custom field value from the topic model.
   * - Renders the value in a table cell with centered alignment.
   * 
   * Properties:
   * - `field`: Retrieves the `version` field value from the topic model using `this.args.topic`.
   * 
   * Template:
   * - Displays the `version` value in a `<td>` element with bold styling and centered alignment.
   */class VersionFieldCell extends _component.default {
    get field() {
      return this.args.topic.get("version");
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <td style="text-align: center; vertical-align: middle;"><b>{{this.field}}</b></td>
      
    */
    {
      "id": "zQhryBZ0",
      "block": "[[[1,\"\\n    \"],[10,\"td\"],[14,5,\"text-align: center; vertical-align: middle;\"],[12],[10,\"b\"],[12],[1,[30,0,[\"field\"]]],[13],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-nc-customfields/discourse/item/version-field-cell.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = VersionFieldCell;
});