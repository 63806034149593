define("discourse/plugins/discourse-nc-customfields/discourse/connectors/topic-title/topic-custom-field-topic-title", ["exports", "@glimmer/component", "@ember/controller"], function (_exports, _component, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
   * type:        step
   * number:      8
   * title:       Display your field value in the topic
   * description: Display the value of your custom topic field below the
   *              title in the topic
   *              list.
   */
  /**
   * This component displays the values of custom topic fields below the title in the display of a topic.
   * It retrieves the custom field values from the topic model and makes them accessible for rendering.
   * 
   * Key Features:
   * - Displays the `version` custom field value below the topic title.
   * - Displays the `isBlockedInProduction` custom field value below the topic title.
   * 
   * Properties:
   * - `versionValue`: Retrieves the `version` field value from the topic model.
   * - `blockedProdValue`: Retrieves the `isBlockedInProduction` field value from the topic model.
   * 
   * Dependencies:
   * - `outletArgs`: Provides access to the topic model for retrieving custom field values.
   * - `topic`: Provides access to the topic controller for retrieving custom field values (in the hbs file).
   */
  class TopicCustomFieldTopicTitle extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "topic", [_controller.inject]))();
    #topic = (() => (dt7948.i(this, "topic"), void 0))();
    get versionValue() {
      return this.args.outletArgs.model.get("version");
    }
    get blockedProdValue() {
      return this.args.outletArgs.model.get("isBlockedInProduction");
    }
  }
  _exports.default = TopicCustomFieldTopicTitle;
});