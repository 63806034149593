define("discourse/plugins/discourse-nc-customfields/global", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CUSTOM_FIELDS = void 0;
  /**
   * CUSTOM_FIELDS
   * 
   * This constant defines the custom fields used throughout the application for the js files.
   * Each field is represented as an object with the following properties:
   * - `name` (String): The unique identifier for the custom field.
   * - `type` (String): The data type of the custom field. Supported field types: boolean, integer, string, json.
   * 
   * Fields:
   * - `version`: A string field representing the version of the topic.
   * - `isBlockedInProduction`: A boolean field indicating whether the topic is blocked in production.
   * 
   * This configuration is used to dynamically integrate custom fields into various parts of the application,
   * such as topic creation, editing, and rendering.
   * Don't forget to add the field to the plugin.rb file as well.
   */
  const CUSTOM_FIELDS = _exports.CUSTOM_FIELDS = [{
    name: 'version',
    type: 'string'
  }, {
    name: 'isBlockedInProduction',
    type: 'boolean'
  }];
});