define("discourse/plugins/discourse-nc-customfields/discourse/templates/connectors/edit-topic/topic-custom-field-edit-topic", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <br/>
  <div class="topic-custom-field-edit">
    <!-- Input for the version custom field -->
    <TopicCustomFieldInput
      @fieldName={{this.versionFieldName}}
      @fieldValue={{this.versionFieldValue}}
      @onChangeField={{this.onVersionChangeField}}
    />
    <!-- Input for the is blocked in production custom field -->
    <TopicCustomFieldInput  
      @fieldName={{this.blockedFieldName}}
      @fieldValue={{this.blockedFieldValue}}
      @onChangeField={{this.onBlockedChangeField}}
    />
  </div>
  */
  {
    "id": "h/aCJpbH",
    "block": "[[[10,\"br\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"topic-custom-field-edit\"],[12],[1,\"\\n  \"],[3,\" Input for the version custom field \"],[1,\"\\n  \"],[8,[39,2],null,[[\"@fieldName\",\"@fieldValue\",\"@onChangeField\"],[[30,0,[\"versionFieldName\"]],[30,0,[\"versionFieldValue\"]],[30,0,[\"onVersionChangeField\"]]]],null],[1,\"\\n  \"],[3,\" Input for the is blocked in production custom field \"],[1,\"\\n  \"],[8,[39,2],null,[[\"@fieldName\",\"@fieldValue\",\"@onChangeField\"],[[30,0,[\"blockedFieldName\"]],[30,0,[\"blockedFieldValue\"]],[30,0,[\"onBlockedChangeField\"]]]],null],[1,\"\\n\"],[13]],[],false,[\"br\",\"div\",\"topic-custom-field-input\"]]",
    "moduleName": "discourse/plugins/discourse-nc-customfields/discourse/templates/connectors/edit-topic/topic-custom-field-edit-topic.hbs",
    "isStrictMode": false
  });
});